import React from "react";
import {Link} from "react-scroll/modules";

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <p><img src="https://img.icons8.com/ios/50/ffffff/secured-letter--v2.png" alt="Email" className="icon"/>   magalysfoundation@gmail.com </p>
                        <br />
                        <p><img src="https://img.icons8.com/ios/50/ffffff/cell-phone.png" alt="Email" className="icon"/>   +1-407-930-6226 </p>
                        <br />
                        <p><img src="https://img.icons8.com/ios/50/FFFFFF/whatsapp--v1.png" alt="Email" className="icon"/>   +1-407-864-3793 </p>
                        <br />
                        <p><img src="https://img.icons8.com/ios/50/ffffff/google-maps-new--v2.png" alt="Email" className="icon"/>   9501 Satellite Blvd Ste 105,<br />
                        &emsp;  &emsp;  &emsp;Orlando, Florida 32837</p>
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-6">
                        <div className="row">
                            <div className="col">
                                <Link smooth={true} to="home" className="fot-link" href="#">home <span className="sr-only">(current)</span></Link><br/>
                                {/* <Link smooth={true} to="about-us" offset={-110} className="fot-link" href="#">nosotros</Link><br/>
                                <Link smooth={true} to="services" offset={-110} className="fot-link" href="#">servicios</Link><br/> */}
                                <Link smooth={true} to="contact" offset={-110} className="fot-link" href="#">contactános</Link><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
                        <div className="d-flex justify-content-center">
                            <a href="https://magalysinsurance.com"><img src="https://img.icons8.com/ios/50/ffffff/monitor--v1.png" alt="Magaly's Insurance" className="icon"/></a>
                        </div>
                        <div className="col-lg-12 col-md-5 col-sm-6 align-items-center">
                            <p className="pt-3 text-center">
                                Copyright&copy;
                                {new Date().getFullYear()}&nbsp;<br />
                                Magaly's Foundation | 
                                All Rights Reserved
                            </p>
                            <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=hntSEgJciaRThjywSD7UvvBgAGqC7G6GcELme6wHQ8PaWfV3kraapjp4zn1s"></script></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
